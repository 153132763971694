@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn-primary {
      @apply font-bold text-white bg-teal-900 transition duration-300 ease-in-out hover:bg-teal-700 py-2 px-4 rounded disabled:opacity-75 !important;
    }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tooltip-measure {
    opacity: 1;
    font-weight: bold;
}

.tooltip-static {
    background-color: #ffcc33;
}

.tooltip-measure:before,
.tooltip-static:before {
    border-top: 6px solid rgba(0, 0, 0, 0.5);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
}

.tooltip-static:before {
    border-top-color: #ffcc33;
}

.switcher select {
    width: 99px;
    height: 30px;
  }